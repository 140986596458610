* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: Roboto, Arial, sans-serif;
    text-rendering: optimizeLegibility;
}

/* Basic elements defined */
html,
body {
    height: 100vh!important;
    width: 100%;
    font-size: 10pt;
    /* 13.33 px/rem */
    color: #666;
    overflow: hidden;
    background-color: silver;
    /* box-shadow: inset 0 10rem #3949AB; */
    scroll-behavior: smooth;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

*::-webkit-scrollbar{
    width: 0.5px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

#app-container {
    position: relative;
    display: flex;
    /* max-width: 95rem; */
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.25rem;
    box-shadow: 1px 2px 0.3rem gray;
    background-color: #eef2f5;
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.app-content {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.top-bar-app{
    background: #fff;
    width: 100%;
    z-index: 9999;
}

.app-chat.detail-contact .top-bar-app{
    background: #b0bec5;
}
.info-view .top-bar-app{
    background: #ebebeb;
}

.header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: rgb(80, 109, 173);
    z-index: 20;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    padding-bottom: 0;
    /* padding: 5px 0px; */
    /* min-height: 45px; */
}

.header .logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(80, 109, 173); */
    padding: 5px;
    cursor: pointer;
}

.header .logo{
    padding-left: 26px;
    display: flex;
    justify-content: start;
}

.header .logo img {
    vertical-align: middle;
    border: 0;
    max-height: 40px;
}

.header .logo span {
    font-size: 22px;
    margin-left: 10px;
    color: rgb(255, 255, 255);
}

.header .header-root {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.header-root .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-top {
    /* background-color: rgb(250, 235, 204); */
    max-height: 53px;
    z-index: 10;
    padding: 5px 20px;
    height: 50px;
}

.header-container-left,
.header-container-right {
    display: flex;
    align-items: center;
}

.header-acc-name {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;
    color: #000;
    font-size: .9rem;
    padding: 5px 15px 5px 5px;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
}
.header-acc-name .header-avatar{
    width: 26px;
    height: 26px;
    margin-left: 0px;
    margin-right: 5px;
}

.header-avatar {
    width: 30px;
    height: 30px;
    margin: 0px 10px;
}

.header-list-company i {
    /* margin-right: 10px; */
}

.header-list-company i.fa-plus-circle {
    font-size: 24px;
}

.header-list-company li {
    /* padding: 0px 20px; */
    padding: 8px 10px;
    min-width: 250px !important;
    padding-left: 45px;
    position: relative;
}

.header-list-company {
    border-radius: 5px !important;
    overflow: hidden;
    max-height: 400px;
    overflow-y: auto;
}

.header-list-company li .avatar-c {
    width: 24px;
    height: 24px;
    position: relative;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
}

.header-list-company li .icon {
    width: 24px;
    height: 24px;
    position: relative;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .14);
    display: flex;
    align-items: center;
    justify-content: center;
}

.shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.header-root .header-container .company-select {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;
    color: #000;
    font-size: .9rem;
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
    /* margin-right: 10px; */
}

.header-root .header-container .company-select i {
    margin-left: 10px;
}
.header .btn-bar{
    display: none;
}

.modal-h-antd input,
.modal-h-antd textarea {
    outline: none;
}

.modal-h-antd .ant-form-item-explain {
    margin: 5px 0px;
}

.privacy-container {
    margin: 50px 150px;
}

.btn-login {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;
    color: #000;
    font-size: .9rem;
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.d-none {
    display: none
}

.ant-cascader-menu {
    min-width: 230px !important;
    height: 435px !important;
    font-size: 14px !important;
}

.input-ant-m,
.input-ant-m input {
    border-radius: 20px !important
}

.ant-tag {
    border-radius: 20px !important;
}

.ant-tag.order-tag-filter {
    background: rgb(255, 255, 255);
    border-style: dashed;
    height: 32px;
    text-align: center;
    font-size: 14px;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
}

.ant-picker-input>input {
    text-align: center;
}

.ant-picker-range {
    /* border-radius: 20px !important; */
    /* font-size: 14px !important; */
}

.ant-select-selection,
.ant-input {
    border: 1px solid #e9e9e9;
}

.modal-noti {
    width: 500px !important;
}

.modal-noti.ant-modal-confirm .ant-modal-body {
    padding: 20px 15px;
}

.modal-noti .content {
    font-size: 15px;
}

.modal-noti .ant-modal-confirm-title {
    font-size: 20px !important;
}

table.table tr td,
table.table tr th {
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    font-size: 14px;
    color: #202224;
    border-bottom: 1px solid #eff2f5;
    padding: 12px 8px;
    color: #575757;
}

table.table thead tr th {
    background-color: #f5f6fa;
    text-transform: uppercase;
    padding: 6px 8px;
    white-space: nowrap;
}

.list-filter-h {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.list-table button,
.list-table input,
.list-table .ant-input-affix-wrapper {
    border-radius: 5px;
}

.list-table .menu-tool button {
    border-radius: 100px;
}

.text-left {
    text-align: left;
}

.form-broadcast .ant-picker {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-right: 20px;
}

.form-broadcast .ant-picker input {
    padding: 0px !important;
}

.form-broadcast .setup_time {
    display: flex;
}

.form-broadcast .setup_time input {
    height: 30px !important;
}

.menu {
    margin: 0px;
}

.menu li {
    list-style: none;
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
}

.menu li:hover {
    background-color: #ecf5ff;
    color: #66b1ff;
}

.text-success {
    color: #53c51a;
}

.text-danger {
    color: #f5222d;
}

.ant-message-custom-content {
    max-width: 600px;
}

.ant-input {
    /* padding: 8px 10px!important; */
}

@media (min-width: 576px) {}

@media (max-width: 768px) {
    .privacy-container {
        margin: 20px;
    }

    .list-connect .item-data .item .name-connnect {
        font-size: 14px;
        margin-left: 10px;
    }

    .last_time_connect {
        display: none;
    }

    .list-connect .item-data .item .avatar-c {
        width: 30px;
        height: 30px;
    }

    .dashboard-container .list-connect .item-data {
        padding: 5px 10px;
    }

    .header-top {
        padding: 5px 10px;
    }

    .header-acc-name {
        display: none;
    }

    .header-root .header-container .company-select {
        margin: 0px;
    }
    .header .logo{
        display: none;
    }
}

@media (max-width: 992px) {
    .header .logo {
        width: 56px !important;
    }

    .header .logo span {
        display: none;
    }
}

@media (max-width: 1366px) {
    .header .logo {
        width: 20%;
    }

    .header {
        padding: 0px;
    }
}

.custom-control-label::before,
.custom-control-label::after {
    top: 3px;
}

.css-control {
    position: relative;
    display: inline-block;
    padding: 6px 0;
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
}

.css-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.css-control-input:focus~.css-control-indicator {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
}

.css-control-input~.css-control-indicator {
    position: relative;
    display: inline-block;
    margin-top: -3px;
    margin-right: 3px;
    vertical-align: middle;
}

.css-control-input~.css-control-indicator::after {
    position: absolute;
    content: "";
}

.css-control.css-control-sm {
    padding: 3px 0 4px;
    font-size: 1rem;
}

.css-control.css-control-lg {
    padding: 7px 0;
    font-size: 1.142857143rem;
}

.css-control.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.css-control+.css-control {
    margin-left: 10px;
}

.css-radio .css-control-input~.css-control-indicator {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
}

.css-radio .css-control-input~.css-control-indicator::after {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.15s ease-out;
}

.css-radio .css-control-input:checked~.css-control-indicator::after {
    opacity: 1;
}

.css-radio:hover .css-control-input~.css-control-indicator {
    border-color: #e4e7ed;
}

.css-radio.css-control-sm .css-control-input~.css-control-indicator {
    width: 16px;
    height: 16px;
}

.css-radio.css-control-lg .css-control-input~.css-control-indicator {
    width: 26px;
    height: 26px;
}

.css-radio.css-control-primary .css-control-input:checked~.css-control-indicator::after {
    background-color: #3f9ce8;
}

.css-radio.css-control-secondary .css-control-input:checked~.css-control-indicator::after {
    background-color: #6c757d;
}

.css-radio.css-control-info .css-control-input:checked~.css-control-indicator::after {
    background-color: #26c6da;
}

.css-radio.css-control-success .css-control-input:checked~.css-control-indicator::after {
    background-color: #9ccc65;
}

.css-radio.css-control-warning .css-control-input:checked~.css-control-indicator::after {
    background-color: #ffca28;
}

.css-radio.css-control-danger .css-control-input:checked~.css-control-indicator::after {
    background-color: #ef5350;
}

.css-checkbox .css-control-input~.css-control-indicator {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    transition: background-color 0.15s;
}

.css-checkbox .css-control-input~.css-control-indicator::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 10px;
    color: #fff;
    line-height: 18px;
    content: "\F00C";
    text-align: center;
}

.css-checkbox:hover .css-control-input~.css-control-indicator {
    border-color: #e4e7ed;
}

.css-checkbox.css-control-sm .css-control-input~.css-control-indicator {
    width: 16px;
    height: 16px;
}

.css-checkbox.css-control-sm .css-control-input~.css-control-indicator::after {
    font-size: 8px;
    line-height: 15px;
}

.css-checkbox.css-control-lg {
    padding: 6px 0 7px;
}

.css-checkbox.css-control-lg .css-control-input~.css-control-indicator {
    width: 28px;
    height: 28px;
}

.css-checkbox.css-control-lg .css-control-input~.css-control-indicator::after {
    font-size: 14px;
    line-height: 26px;
}

.css-checkbox.css-checkbox-rounded .css-control-input~.css-control-indicator {
    border-radius: 2px;
}

.css-checkbox.css-control-primary .css-control-input:checked~.css-control-indicator {
    background-color: #3f9ce8;
    border-color: #1776c3;
}

.css-checkbox.css-control-secondary .css-control-input:checked~.css-control-indicator {
    background-color: #6c757d;
    border-color: #494f54;
}

.css-checkbox.css-control-info .css-control-input:checked~.css-control-indicator {
    background-color: #26c6da;
    border-color: #1a8b99;
}

.css-checkbox.css-control-success .css-control-input:checked~.css-control-indicator {
    background-color: #9ccc65;
    border-color: #76ac39;
}

.css-checkbox.css-control-warning .css-control-input:checked~.css-control-indicator {
    background-color: #ffca28;
    border-color: #dba500;
}

.css-checkbox.css-control-danger .css-control-input:checked~.css-control-indicator {
    background-color: #ef5350;
    border-color: #de1814;
}

.css-switch {
    padding: 3px 0;
}

.css-switch .css-control-input~.css-control-indicator {
    width: 51px;
    height: 30px;
    background-color: #eee;
    border-radius: 30px;
    transition: background-color 0.3s;
}

.css-switch .css-control-input~.css-control-indicator::after {
    top: 2px;
    bottom: 2px;
    left: 2px;
    width: 26px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.15s ease-out;
}

.css-switch .css-control-input:checked~.css-control-indicator {
    background-color: #ddd;
}

.css-switch .css-control-input:checked~.css-control-indicator::after {
    box-shadow: -2px 0 3px rgba(0, 0, 0, 0.3);
    transform: translateX(20px);
}

.css-switch.css-control-sm {
    padding: 3px 0;
}

.css-switch.css-control-sm .css-control-input~.css-control-indicator {
    width: 36px;
    height: 20px;
}

.css-switch.css-control-sm .css-control-input~.css-control-indicator::after {
    width: 16px;
}

.css-switch.css-control-sm .css-control-input:checked~.css-control-indicator::after {
    transform: translateX(15px);
}

.css-switch.css-control-lg {
    padding: 4px 0;
}

.css-switch.css-control-lg .css-control-input~.css-control-indicator {
    width: 65px;
    height: 34px;
}

.css-switch.css-control-lg .css-control-input~.css-control-indicator::after {
    width: 30px;
}

.css-switch.css-control-lg .css-control-input:checked~.css-control-indicator::after {
    transform: translateX(30px);
}

.css-switch.css-switch-square .css-control-input~.css-control-indicator,
.css-switch.css-switch-square .css-control-input~.css-control-indicator::after {
    border-radius: 0;
}

.css-switch.css-control-primary .css-control-input:checked~.css-control-indicator {
    background-color: #3f9ce8;
}

.css-switch.css-control-secondary .css-control-input:checked~.css-control-indicator {
    background-color: #6c757d;
}

.css-switch.css-control-info .css-control-input:checked~.css-control-indicator {
    background-color: #26c6da;
}

.css-switch.css-control-success .css-control-input:checked~.css-control-indicator {
    background-color: #9ccc65;
}

.css-switch.css-control-warning .css-control-input:checked~.css-control-indicator {
    background-color: #ffca28;
}

.css-switch.css-control-danger .css-control-input:checked~.css-control-indicator {
    background-color: #ef5350;
}

.modal-form-h .ant-form-item-label {
    /* display: block;
    width: 100%;
    text-align: left;
    font-weight: 500; */
}

.ant-form .ant-form-item {
    margin-bottom: 12px;
}

.table-fixed thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.form-antd-h .ant-form-item-label, .form-antd-hh .ant-form-item-label {
    /* width: 100%;
    text-align: left; */
}

.form-antd-h .ant-form-item {
    margin-bottom: 5px !important;
}

.ant-tag-h {
    border-radius: 5px !important;
}

.sketch-picker {
    box-sizing: border-box !important;
    box-shadow: unset !important;
    border: 1px solid #ececec;
}

.img-avatar {
    display: inline-block !important;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.block {
    margin-bottom: 24px;
    background-color: #fff;
    box-shadow: 0 1px 1px #e4e7ed;
}

.block .block,
.content-side .block {
    box-shadow: none;
}

.block-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 14px 20px;
    transition: opacity .2s ease-out;
}

.block-header.block-header-rtl {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.block-header.block-header-rtl .block-title {
    text-align: right;
}

.block-header.block-header-rtl .block-options {
    padding-right: 10px;
    padding-left: 0;
}

.block-header-default {
    background-color: #f6f7f9;
}

.block-title {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 28px;
    margin: 0;
    font-size: 1.142857rem;
    font-weight: 400;
    line-height: 28px;
}

.block-title small {
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: #646464;
}

.block-content {
    transition: opacity .2s ease-out;
    margin: 0 auto;
    padding: 20px 20px 1px;
    width: 100%;
    overflow-x: visible;
}

.block-content .pull-t {
    margin-top: -20px;
}

.block-content .pull-b {
    margin-bottom: -1px;
}

.block-content .pull-t-b {
    margin-top: -20px;
    margin-bottom: -1px;
}

.block-content .pull-r-l {
    margin-right: -20px;
    margin-left: -20px;
}

.block-content .pull-all {
    margin: -20px -20px -1px;
}

.block-content.block-content-full {
    padding-bottom: 20px;
}

.block-content.block-content-full .pull-b,
.block-content.block-content-full .pull-t-b,
.block-content.block-content-full .pull-all {
    margin-bottom: -20px;
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push>div {
    margin-bottom: 20px;
}

.block-content .items-push-2x>div {
    margin-bottom: 40px;
}

.block-content .items-push-3x>div {
    margin-bottom: 60px;
}

.block-content.block-content-sm {
    padding-top: 10px;
}

.block-content.block-content-sm .pull-t,
.block-content.block-content-sm .pull-t-b,
.block-content.block-content-sm .pull-all {
    margin-top: -10px;
}

.block-content.block-content-sm.block-content-full {
    padding-bottom: 10px;
}

.block-content.block-content-sm.block-content-full .pull-b,
.block-content.block-content-sm.block-content-full .pull-t-b,
.block-content.block-content-sm.block-content-full .pull-all {
    margin-bottom: -10px;
}

.block-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.block-table .block-row {
    display: table-row;
}

.block-table .block-cell {
    display: table-cell;
    padding: 20px;
    vertical-align: middle;
}

.block.block-bordered {
    border: 1px solid #e4e7ed;
    box-shadow: none;
}

.block.block-rounded {
    border-radius: 4px;
}

.block.block-rounded>.block-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.block.block-rounded>.block-header:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.block.block-rounded>.block-content:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.block.block-rounded>.block-content:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.block.block-themed>.block-header {
    border-bottom: none;
    color: #fff;
    background-color: #3f9ce8;
}

.block.block-themed>.block-header>.block-title {
    color: rgba(255, 255, 255, 0.9);
}

.block.block-themed>.block-header>.block-title small {
    color: rgba(255, 255, 255, 0.7);
}

.block.block-transparent {
    background-color: transparent;
    box-shadow: none;
}

.block.block-shadow {
    box-shadow: 0 15px 30px rgba(33, 37, 41, 0.05);
}

.block.block-mode-hidden.block-bordered>.block-header {
    border-bottom: none;
}

.block.block-mode-hidden>.block-content {
    display: none;
}

.block.block-mode-loading {
    position: relative;
    overflow: hidden;
}

.block.block-mode-loading>.block-header,
.block.block-mode-loading>.block-content,
.block.block-mode-loading>.nav-tabs {
    opacity: .05;
}

.block.block-mode-loading::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    content: " ";
}

.block.block-mode-loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #343a40;
    font-family: Simple-Line-Icons;
    font-size: 18px;
    text-align: center;
    z-index: 2;
    content: '\e09a';
    -webkit-animation: fa-spin 1.75s infinite linear;
    animation: fa-spin 1.75s infinite linear;
    border-radius: 25px;
    box-shadow: 0 0 15px 15px rgba(52, 58, 64, 0.05);
}

.block.block-mode-loading.block-mode-hidden::after {
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-shadow: 0 0 8px 8px rgba(52, 58, 64, 0.05);
}

.block.block-mode-loading.block-mode-loading-inverse::after {
    background-color: #343a40;
    color: #fff;
    box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.5);
}

.block.block-mode-loading.block-mode-loading-location::after {
    content: '\e06e';
}

.block.block-mode-loading.block-mode-loading-energy::after {
    content: '\e020';
}

.block.block-mode-loading.block-mode-loading-refresh::after {
    font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
    font-weight: 900;
    content: '\f021';
}

.block.block-mode-loading.block-mode-loading-sun::after {
    font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
    content: '\f185';
}

.block.block-mode-loading.block-mode-loading-repeat::after {
    font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
    font-weight: 900;
    content: '\f01e';
}

.block.block-mode-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1035;
    margin-bottom: 0;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
}

.block.block-mode-fullscreen.block-bordered {
    border: none;
}

.block.block-mode-fullscreen.block-rounded {
    border-radius: 0;
}

.block.block-mode-fullscreen.block-transparent {
    background-color: #fff;
}

.block.block-mode-pinned {
    position: fixed;
    right: 10px;
    bottom: 0;
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 300px;
    z-index: 1031;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.block.block-mode-pinned>.block-content {
    max-height: 250px;
    overflow-y: auto;
}

a.block {
    display: block;
    color: #575757;
    font-weight: normal;
    transition: all .12s ease-out;
}

a.block:hover {
    color: #575757;
    opacity: .65;
}

a.block:active {
    opacity: 1;
}

a.block.block-link-rotate:hover {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
    opacity: 1;
}

a.block.block-link-rotate:active {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

a.block.block-link-pop:hover {
    box-shadow: 0 2px 30px #d8dde5;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    opacity: 1;
}

a.block.block-link-pop:active {
    box-shadow: 0 2px 10px #edeff3;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

a.block.block-link-shadow:hover {
    box-shadow: 0 0 36px #d8dde5;
    opacity: 1;
}

a.block.block-link-shadow:active {
    box-shadow: 0 0 10px #e4e7ed;
}

.block.block-fx-rotate {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
    opacity: 1;
}

.block.block-fx-pop {
    box-shadow: 0 2px 30px #d8dde5;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    opacity: 1;
}

.block.block-fx-shadow {
    box-shadow: 0 0 36px #d8dde5;
    opacity: 1;
}

.block-options {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 10px;
    min-height: 28px;
}

.block-options .block-options-item {
    padding: 6px 5px;
    line-height: 1.2;
}

.block.block-themed>.block-header .block-options .block-options-item {
    color: #fff;
}

.block-options .block-options-item,
.block-options .dropdown,
.block-options .dropup,
.block-options .dropright,
.block-options .dropleft {
    display: inline-block;
}

.block-sticky-options {
    position: relative;
}

.block-sticky-options .block-options {
    position: absolute;
    top: 14px;
    right: 20px;
}

.block-sticky-options .block-options.block-options-left {
    right: auto;
    left: 20px;
    padding-right: 10px;
    padding-left: 0;
}

.btn-block-option {
    display: inline-block;
    padding: 6px 5px;
    line-height: 1.2;
    color: #6c757d;
    background: none;
    border: none;
    cursor: pointer;
}

.btn-block-option .si {
    position: relative;
    top: 1px;
}

.btn-block-option:hover {
    text-decoration: none;
    color: #343a40;
}

a.btn-block-option:focus,
.active>a.btn-block-option,
.show>button.btn-block-option {
    text-decoration: none;
    color: #343a40;
}

.btn-block-option:active {
    color: #6c757d;
}

a.btn-block-option:focus,
.active>a.btn-block-option,
.show>button.btn-block-option {
    text-decoration: none;
    color: #343a40;
}

.block.block-themed>.block-header>.block-options .btn-block-option,
.block.block-themed>.block-content>.block-options .btn-block-option {
    color: #fff;
    opacity: .7;
}

.block.block-themed>.block-header>.block-options .btn-block-option:hover,
.block.block-themed>.block-content>.block-options .btn-block-option:hover {
    color: #fff;
    opacity: 1;
}

.block.block-themed>.block-header>.block-options .btn-block-option:active,
.block.block-themed>.block-content>.block-options .btn-block-option:active {
    color: #fff;
    opacity: .6;
}

.block.block-themed>.block-header>.block-options a.btn-block-option:focus,
.block.block-themed>.block-header>.block-options .active>a.btn-block-option,
.block.block-themed>.block-header>.block-options .show>button.btn-block-option,
.block.block-themed>.block-content>.block-options a.btn-block-option:focus,
.block.block-themed>.block-content>.block-options .active>a.btn-block-option,
.block.block-themed>.block-content>.block-options .show>button.btn-block-option {
    color: #fff;
    opacity: 1;
}

.bg-body-light {
    background-color: #f6f7f9 !important;
}
.bg-primary-lighter {
    background-color: #c8e2f8 !important;
}
  
.ant-modal-content{
    border-radius: 5px!important;
}
.ant-modal-close{
    outline: none!important;
}
.ant-modal-20 .ant-modal-body{
    padding: 20px!important;
}
.ant-image-preview-img{
    max-width: 95%!important;
    max-height: 90%!important;
}
.company-picture-preview{
    height: 64px;
}
.badge.badge-status{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0px;
}
.offline-status-staff{
    /* position: absolute; */
}
.emoji-mart-preview{
    display: none;
}

#pot{
    top:10px;
    position:fixed;
    animation: linear infinite alternate;
    animation-name: runPot;
    animation-duration: 28s;
    z-index: 999;
}     
#pot span{
    color: #fff;
    top: 0;
    left: 100%;
    position: absolute;
    white-space: nowrap;
    background: rgb(80, 109, 173);
}
@keyframes runPot {
    0% { left: 0; }
    50%{ left : 80%; }
    100% { left: 0; }
}
#sidebar-filter.chat-offline{
    background: linear-gradient( -180deg,#f53d2d,#f63);
}
#sidebar-filter.chat-offline ul li svg, #sidebar-filter.chat-offline ul li i {
    color: #fff;
}

#sidebar-filter{
    position: relative;
}
#sidebar-filter .version{
    color: #97a2af;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
    font-weight: bold;
}

.phone-call {
    width: 50px;
    height: 50px;
    background: #10b418;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    line-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.phone-call:before {
    position: absolute;
    content: " ";
    top: -15px;
    left: -15px;
    background-color: #10b418;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    opacity: 0.6;
    -webkit-animation: pulse 1s ease-out;
    animation: pulse 1.8s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.phone-call i{
    z-index: 1;
    -webkit-animation: phonerotate 0.8s ease-out;
    animation: phonerotate 0.8s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(0.3);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(0.6);
        opacity: .6;
    }
    75% {
        -webkit-transform: scale(0.9);
        opacity: .3;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    25% {
        transform: scale(0.3);
        opacity: 1;
    }
    50% {
        transform: scale(0.6);
        opacity: .6;
    }
    75% {
        transform: scale(0.9);
        opacity: .3;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes phonerotate {
    0% {
        transform: rotate(0);
        opacity: .2;
    }
    25% {
        transform: rotate(25deg);
        opacity: 1;
    }
    50% {
        transform: rotate(-25deg);
        opacity: .8;
    }
    75% {
        transform: rotate(25deg);
        opacity: .6;
    }
    100% {
        transform: rotate(0);
        opacity: 0.3;
    }
}
@keyframes phonerotate {
    0% {
        transform: rotate(0);
        opacity: .2;
    }
    25% {
        transform: rotate(25deg);
        opacity: 1;
    }
    50% {
        transform: rotate(-25deg);
        opacity: .8;
    }
    75% {
        transform: rotate(25deg);
        opacity: .6;
    }
    100% {
        transform: rotate(0);
        opacity: 0.3;
    }
}
#phone-call{
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    display: flex;
    align-items: center;
    transform: translateY(160px);
    transition: 0.3s all ease-in-out;
}
#phone-call.active{
    transform: translateY(0);
}
#phone-call .desc{
    font-size: 18px;
    margin-right: 10px;
    color: #fff;
    text-shadow: 0 0 3px 6px #000;
    background: #0cb417;
    border-radius: 5px;
    padding: 2px 10px;
    text-align: center;
}
#phone-call .desc p{
    margin-bottom: 3px;
}
#phone-call .desc p:last-child{
    margin-bottom: 0px;
}
#phone-call .desc .title{
    font-size: 15px;
}

/** footer app */

.footer-app {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;
}
.footer-app .tab-footer {
    display: flex;
    background: #fff;
    margin: auto;
    box-shadow: 0 -1px 2px rgb(0 0 0 / 3%)
}
.footer-app .tab-footer .tab-item.active {
    color: #0b7cfe;
    cursor: pointer;
}
.footer-app .tab-footer .tab-item {
    flex: 1;
    min-height: 0;
    min-width: 0;
    text-align: center;
    display: block;
    padding: 7px 5px 5px;
    box-sizing: border-box;
    color: #a4aab2;
    font-size: 13px;
    position: relative;
    transition: 0.2s all ease-in-out;
}
.footer-app .tab-footer .tab-item i {
    font-size: 20px;
    position: relative;
    transition: 0.2s all ease-in-out;
}

.footer-app .tab-footer .tab-item p{
    margin-bottom: 0px;
}

.footer-app .tab-footer .tab-item .count{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background: red;
    box-sizing: border-box;
    border: 2px solid #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff;
    font-size: 8px;
}
.footer-app .tab-footer a{
    text-decoration: none;
}

.footer-app .tab-footer .tab-item.active{
    /* font-size: 14px; */
}

.footer-app .tab-footer .tab-item.active i{
    /* font-size: 21px; */
}

.menu-tab ul{
    /* overflow: auto; */
    /* white-space: nowrap; */
}
.menu-tab ul li{
    display: inline-block;
    padding: 5px 20px;
}
.menu-tab ul li.active{
    border-bottom: 2px solid #000;
}
.menu-tab ul li a{
    font-size: 16px;
    color: #000;
}
.btn-arrow-back, .btn-arrow-back i{
    font-size: 22px;
    color: #757575;
}

.birthday-hidden .ant-form-item-control{
    display: none;
}
.ant-message .ant-message-custom-content{
    display: flex;
    align-items: center;
}

#box-touch-left, #box-touch-right{
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 8px;
    background: red;
    z-index: 99;
    opacity: 0;
}

.app-native #box-touch-left{
    display: block!important;
    left: 0;
}
.app-native #box-touch-right{
    display: block!important;
    right: 0;
}
.touch-arrow{
    position: fixed;
    color: #b0bec5;
    font-size: 28px;
    transition: 0.2s scale ease-in-out;
    z-index: 99999;
    top: -300px;
    left: -300px;
}
.app-native #topic-caption-panel #hide-message-view{
    margin-left: 5px;
}
.app-native .sidebar .sidebar-menu a{
    text-decoration: unset;
}
.app-native .tool-filter-tag{
    /* display: none; */
}
.btn-filter-tag{
    padding-left: 10px;
    font-size: 20px;
    color: #53647a;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-filter-tag.active{
    
}

.ant-popover.popover-filter-tag .ant-popover-inner-content{
    width: 150px;
}

.ant-popover{
    /* overflow: hidden; */
}

/* .app-native .app-content{
    flex-direction: row-reverse;
} */

.app-native .footer-app{
    z-index: 100;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input{
    /* height: 36px!important; */
}


.page-feedback .ant-form-item .ant-form-item-label{
    font-weight: 500;
}
.page-feedback .ant-form-item .ant-form-item-label label{
    font-size: 15px!important;
    height: auto!important;
}


.page-feedback #contentment .ant-radio{
    display: none;
}
.page-feedback #contentment .ant-radio+ span{
    font-size: 40px;
    color: #243b55;
}
.page-feedback #contentment .ant-radio.ant-radio-checked + span{
    color: #e91e63;
}

.gx-5{
    margin-right: -5px;
    margin-left: -5px;
  }
  .gx-5 > [class*="col-"]{
    padding-left: 5px;
    padding-right: 5px;
  }