#content_tokhai, #content_tra_cuu{
    label, td, td span{
        font-size: 16px;
    }
    input, textarea, select{
        font-size: 15px;
    }
    button#submitform{
        /* height: 40px; */
    }
    h5{
        display: inline-block;
        border-bottom: 3px solid red;
        padding-bottom: 5px;
    }
    .ant-form-item-explain{
        display: none!important;
    }
    .label-title{
        margin-top: 20px;
        // margin-left: 20px;
        font-weight: bold;
        border-bottom: 2px solid red;
        padding-bottom: 5px;
        +.ant-table-wrapper{
            margin-left: 20px;
        }
    }
    .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
        margin-left: 20px;
    }
}
#content_tra_cuu{
    label, td, td span{
        color: #212529;
    }
    label, td, td span, a{
        font-size: 15px;
    }
    .label-title-2{
        // margin-left: 20px;
        +.ant-table-wrapper{
            margin-left: 20px;
        }
    }
    table .ant-empty{
        display: none;
    }
    table{
        .ant-table-expanded-row{
            td, td span{
                font-size: 14px;
            }
            .ant-table-cell{
                // padding-left: 0px;
            }
        }
    }
}

#info-view{
    .search-medical{
        h5{
            font-size: 14px;
            border: none;
            padding: 0px;
        }
        label, td, td span, a{
            font-size: 14px!important;
        }
    }
}

.modal-search-medical{
    h5{
        font-size: 14px;
        border: none!important;
        padding: 0px;
    }
    label, td, td span, a{
        font-size: 14px!important;
    }
}

.load-spin-popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background-color: rgba($color: #000000, $alpha: 0.2);
    .load-spin-box{

    }
}

.search-medical-content {
    .label-title{
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(0,0,0,.85);
    }
}

#kbyt-face-box{
    .kbyt-right{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        >div{
            width: 100%;
            height: 50%;
            &:last-child{
                background-color: #eef2f5;
                margin-top: 16px;
            }
        }
        iframe{
            width: 100%;
            border: none;
            height: 100%;
            scale: 0.6;
        }
    }
    .kbyt-neo{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    #kbyt-face{
        height: 100%;
        width: 100%;
        background-color: #eef2f5;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        .kbyt-face-title{
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .kbyt-face-content{
            height: 100%;
            overflow: auto;
            padding-top: 20px;
            text-align: center;
            border-top: 2px solid #fff;
            box-sizing: border-box;
            padding-bottom: 100%;
            &.disabled{
                pointer-events: none;
                .face-item{
                    pointer-events: none;
                }
            }
            .face-item{
                width: 80%;
                max-width: 220px;
                margin: auto;
                margin-bottom: 16px;
                // box-shadow: 0 0 2px 3px rgba(0,0,0,0.1);
                overflow: hidden;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                user-select: none;
                transition: 0.2s all ease-in-out;
                img {
                    transition: 0.2s all ease-in-out;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                    box-shadow: 0 0 2px 3px rgba(80, 109, 173, 0.3);
                }
                &.active{
                    pointer-events: none;
                    transform: scale(1.08);
                    border: 3px solid #1890ff;
                    border-top: 0;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: rgba(0,0,0,.3);
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 0;
                    }
                    &::before {
                        content: "Đang chọn";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 2px 4px;
                        background-color: #1890ff;
                        z-index: 1;
                        color: #fff;
                        font-size: 13px;
                        border-top-right-radius: 6px;
                    }
                }
                .time{
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 11px;
                    color: #fff;
                    background-color: rgba(0,0,0,.6);
                    padding: 0px 2px;
                    display: flex;
                    align-items: center;
                    border-bottom-left-radius: 6px;
                }
                .status-checkin{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 11px;
                    color: #fff;
                    background-color: rgba(0,0,0,.6);
                    padding: 0px 2px;
                    display: flex;
                    align-items: center;
                    border-bottom-left-radius: 6px;
                }
            }
        }
    }
    .kbyt-face-head{
        position: relative;
        .loading{
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
        .preview-faceid{
            width: 40px;
            height: 40px;
            background-color: #fff;
            margin-bottom: 10px;
            overflow: hidden;
            display: flex;
            border-radius: 5px;
            position: absolute;
            right: 10px;
            top: 4px;
            .ant-image{
                width: 100%;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .reload-faceid{
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.sidebar.hide, .header.hide{
    display: none;
}

#app-faceid-info{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 16px;
    user-select: none;
    .app-faceid-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-bottom: 10px;
        justify-content: space-between;
        user-select: none;
        .head{
            text-transform: uppercase;
            padding: 16px;
            >div{
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
            .head-item{
                // border: 1px solid #000;
                // box-shadow: none;
                // padding: 2px 6px;
                // &.head-item-icon{
                //     padding-left: 16px;
                //     position: relative;
                //     box-sizing: border-box;
                // }
                display: flex;
                align-items: flex-end;
                i{
                    margin-right: 4px;
                    height: 22px;
                    font-size: 16px;
                    width: 22px;
                }
                &.head-item-checkin{
                    font-weight: 500;
                    background-color: rgba(190, 190, 190, 0.2);
                    border-radius: 4px;
                    padding: 1px 10px;
                    margin-bottom: 8px;
                }
                .head-item-empty{
                    background: rgba(190, 190, 190, 0.2);
                    border-radius: 4px;
                    min-width: 50%;
                    height: 20px;
                    display: inline-block;
                    margin-bottom: 5px;
                    box-sizing: border-box;
                    font-size: 15px;
                    padding: 1px 6px 1px 8px;
                    line-height: 19px;
                }
            }
        }
        .face-data{
            padding: 16px 0px 0px 16px;
            overflow: auto;
            display: flex;
            align-items: flex-end;
            padding-right: 100px;
            &::-webkit-scrollbar{
                display: none;
            }
            .face-data-item{
                margin-right: 16px;
                position: relative;
                display: flex;
                padding-bottom: 20px;
                .ant-image{
                    width: 130px;
                    height: 150px;
                    border-radius: 6px;
                    overflow: hidden;
                    transition: 0.2s all ease-in-out;
                    z-index: 0;
                    img{
                        height: 100%;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
                .info{
                    position: absolute;
                    bottom: 6px;
                    background-color: #f6b849;
                    border-radius: 6px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    font-size: 12px;
                    color: #fff;
                    white-space: nowrap;
                    padding: 2px 5px;
                    // border: 1px solid #fff;
                    z-index: 2;
                }
                &.active{
                    .ant-image{
                        width: 150px;
                        height: 176px;
                    }
                    .info{
                        background-color: #0f3cd1;
                        font-size: 14px;
                        background-image: linear-gradient(180deg,#0f3cd1 0,#0f3cd1 19%,#000 60%,#000)!important;
                    }
                }
            }
        }
    }
}

.log-face-data{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -6px;
    .log-face-data-item{
        position: relative;
        width: 100px;
        margin-right: 6px;
        margin-bottom: 6px;
        .timestamp{
            position: absolute;
            bottom: 0;
            left: 0;
            color: #fff;
            font-size: 11px;
            background-color: rgba(0, 0, 0, 0.6);
            padding: 0px 2px 0px 4px;
            line-height: 16px;
        }
    }
}