/*response*/
@media (max-width: 1366px) {
    .chat-box {
        padding-bottom: 60px !important;
        padding-top: 10px;
    }

    .caption-panel {
        height: 40px;
        padding: 0px 5px;
    }

    #info-caption-panel {
        height: 42px;
        min-height: 42px;
    }

    .panel-title {
        font-size: 1.3rem;
    }

    #topic-caption-panel .avatar-box {
        width: 32px;
        min-width: 32px;
        height: 32px;
    }

    #topic-caption-panel .avatar-box img {
        vertical-align: top;
    }

    #sendMessage {
        height: 45px;
    }
}

@media (max-width: 1024px) {
    #message-view #info-view {
        position: fixed;
        display: flex;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transform: translateX(100%);
    }

    #message-view #info-view .btn-close {
        display: block;
        padding: 10px
    }
}

@media (max-width: 1024px) {
    #root-chat {
        padding: 0;
    }

    #info-view {
        display: none;
    }
}

@media (max-width: 720px) {
    
}

@media (max-width: 1024px) {
    #app-container {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    #sidepanel {
        width: 100%;
    }

    #sidepanel:not(.tab-login) {
        /* display: none!important; */
    }

    #info-view {
        width: 100%;
    }

    #dummy-view {
        display: none !important;
    }

    #hide-message-view {
        display: block !important;
    }

    .chat-box .bubble div.message-content {
        max-width: auto;
    }

    .chat-box .bubble {
        max-width: 85%;
    }

    .chat-box .menuTrigger {
        opacity: 1;
        /* right: -25px */
    }

    .contact-box>li .tool {
        display: block;
    }

    .contact-box>li .tool .material-icons {
        font-size: 1.5rem;
    }

    .contact-box>li .tool a {
        display: inline-block;
        margin: 0px;
        margin-left: 5px;
    }

    #sidebar-filter {
        flex-shrink: unset;
        /* opacity: 0; */
        transition: 0.1s all ease-in-out;
        position: fixed;
        right: -100%;
    }

    #topic-view {
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0.3;
    }

    #login-view {
        width: 100%;
        height: 100%;
    }

    .tooltip {
        display: none;
    }

    .modal-h .modal-content {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        transform: unset;
        max-height: 100%;
        border-radius: 0px;
    }

    .rdrDefinedRangesWrapper {
        display: none !important;
    }

    #filter-date {
        width: calc(100% - 60px);
    }

    #messages-panel {
        padding: 5px 0px 100px;
    }

    .chat-box.group {
        margin: 0px;
    }

    .chat-box .menuTrigger {
        padding-left: 10px;
    }

    .chat-box .right .menuTrigger {
        padding-right: 10px;
    }

    .modal-direct-new .modal-content {
        min-width: 90%;
    }

    .notify-mes {
        max-width: 350px;
    }

    .modal-image .ant-modal-content {
        max-width: 80%;
    }

    .ant-tooltip {
        display: none !important;
    }

    .ant-popover {
        width: 100%;
    }

    .ant-popover .ant-col-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .modal-content-md {
        width: 100% !important;
    }

    #send-message-panel {
        /* height: 5rem; */
        /* position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; */
    }

    #sidebar-filter.chat-view {
        display: none;
    }

    .detail-contact .header {
        /* display: none; */
    }

    .chat-box .inline-image {
        max-width: 100% !important;
    }

    .chat-menu-bar li.see-more {
        display: inline-block;
    }

    .app-chat .header .btn-bar-chat{
        display: flex;
    }
    .filter-post .select-post .content{
        display: none;
    }
    .filter-post .select-post{
        padding-bottom: 50px;
    }
    .header{
        background: #fff;
        border-bottom: 1px solid #eef2f5;
    }
    .header-root .header-container{
        padding-left: 8px;
    }
    .btn-bar svg{
        fill: #53647a!important;
        width: 35px!important;
    }
    .tool-search{
        border-right: 0px;
    }
    .app-chat.detail-contact .header{
        /* display: none; */
    }
    .header-root .header-container .company-select{
        font-size: 14px;
    }
    .footer-app{
        display: block;
    }
    .app-native #app-container:not(.detail-contact){
        padding-bottom: 55px;
    }
    .app-native ::-webkit-scrollbar, .app-native #info-view-content::-webkit-scrollbar{
        display: none;
    }
    .app-native #messages-panel{
        padding-right: 0px;
    }
    .listdata-img{
        width: 66%!important;
    }
    
    .app-chat #sendMessage, .form-add-mes textarea{
        height: 34px;
        margin-left: 8px;
    }
    .btn-manage-tool i{
        font-size: 22.5px;
    }
    .btn-manage-tool svg{
        width: 23.5px;
        height: 23.5px;
    }
    .app-native #topic-caption-panel{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 5px 5px;
        height: auto;
        min-height: 40px;
    }
    #messages-container{
        padding-top: 39px;
    }
    .app-native #dashboard .sidebar{
        padding-bottom: 150px
    }
    .app-native #sendMessage{
        height: 40px;
    }
    #send-message-panel .list-tags .slick-prev , #send-message-panel .list-tags .slick-next{
        display: none!important;
    }
    #send-message-panel .list-tags .btn-tag-prev{
        top: -23px;
        transform: translateY(0);
        /* left: 2px; */
    }
    #send-message-panel{
        padding-bottom: 5px;
    }
    .form-add-mes-m .btn-manage-tool:first-child{
        margin-left: 0px;
    }
    .btn-manage-tool{
        /* margin-left: 12px; */
        padding: 0px 6px;
        margin-left: 3px;
    }
    .form-add-mes-m .btn-manage-tool:last-child{
        /* padding-right: 6px; */
    }
    .form-add-mes-m #sendMessage{
        margin-right: 5px;
    }
    .dashboard-container .content-m{
        padding: 5px!important;
    }
    #topic-caption-panel{
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
    }
    .tool-filter-text input{
        /* max-height: 50px; */
        height: 40px;
        border: none;
    }
    .ant-popover{
        max-width: 90%;
        width: fit-content;
    }
    .ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight{
        padding-top: 0px!important;
    }
    .ant-popover-placement-right, .ant-popover-placement-rightTop, .ant-popover-placement-rightBottom{
        padding-left: 0px!important;
    }
    .ant-popover.popover-filter-tag .ant-popover-content{
        margin-right: 10px;
    }
    .ant-popover .ant-popover-arrow{
        display: none;
    }
    .chat-menu-bar li a{
        width: 1.1em;
        height: 1.1em;
    }
    #sidebar-filter{
        width: 60px;
        position: fixed;
        right: 0;
        transition: 0.2s all ease-in-out;
        transform: translateX(100%);
        top: 0;
        z-index: 2;
        opacity: 0;
        padding-top: 52px;
    }
    #sidebar-filter.show{
        transform: translateX(0);
        opacity: 1;
        /* display: block!important; */
    }
    #sidebar-filter.show .overlay{
        display: block!important;
        position: fixed;
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        right: 0;
        width: 1000px;
        height: 100%;
    }
    #message-view{
        position: fixed;
        top: 0;
        left: 0;
        /* opacity: 0; */
        transform: translateX(100%);
        z-index: 101;
        transition: 0.3s all ease-in-out;
    }
    #message-view.active{
        transform: translateX(0);
        /* opacity: 1; */
    }
    .app-chat .header-top{
        flex-direction: row-reverse;
        height: auto;
    }
    .detail-contact .header{
        opacity: 0.3;
    }
    .app-native #topic-caption-panel{
        /* min-height: 34px; */
        border-bottom: 1px solid #dddddd;
        box-shadow: none;
    }
    .app-native #message-view{
        background: #fff;
    }
    .message-content .audio{
        /* width: 600px; */
    }
}

@media (min-width: 720px) {
    #send-message-panel .list-tags .item-tag-h.active:after,
    #send-message-panel .list-tags .item-tag-h:hover:after {
        background-color: transparent;
    }
}